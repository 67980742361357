import { useCallback, useMemo } from 'react';
import { useUserContext } from '../../context/user/UserContext';

const useFeatureFlags = () => {
  const { data: userData } = useUserContext();

  const userFeatureFlags = useMemo(() => {
    return userData?.user?.flags ?? [];
  }, [userData?.user?.flags]);

  const isFeatureEnabled = useCallback(
    (featureFlag: Flags) => {
      return userFeatureFlags.includes(featureFlag);
    },
    [userFeatureFlags]
  );

  return {
    userFeatureFlags,
    isFeatureEnabled,
  };
};

export type Flags =
  | 'add-address-prompt'
  | 'avatar-modal-v2'
  | 'drives-and-folders'
  | 'editor-v-11-1-preview-clips'
  | 'project-create-v-2'
  | 'projects-page-v2'
  | 'shared-libraries'
  | 'team-add-seats'
  | 'team-unmanaged-monthly'
  | 'team-unmanaged'
  | 'with-taxes'
  | 'with-onboarding'
  | 'without-maker'
  | 'qa-mode'
  | 'send-feedback-link';

export default useFeatureFlags;
